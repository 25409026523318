<template>
  <div class="text-cover">
    <slot>
      <h1 v-if="hasTitle" class="text-cover--title">{{ title }}</h1>
    </slot>

    <LadStaticCoverImage
      :src="src || url"
      :min-height="imgMinHeight"
      :max-width="imgMaxWidth"
    />
  </div>
</template>

<script>
export default {
  name: 'LadTextCover',
  components: {
    LadStaticCoverImage: () =>
      import(/* webpackChunkName: "lad-static" */ './static'),
  },
  props: {
    title: { type: String, default: '' },
    src: { type: String, default: '' },
    url: { type: String, default: '' },
    imgMaxWidth: { type: Number, default: 0 },
    // eslint-disable-next-line vue/require-prop-type-constructor
    imgMinHeight: { type: String | Number, default: 'auto' },
  },
  computed: {
    hasTitle() {
      return !!this.title
    },
  },
}
</script>

<style lang="scss">
.text-cover {
  position: relative;
  overflow: hidden;
  height: 32vh;
  min-height: 380px;

  &--title {
    position: absolute;
    z-index: 1;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 20px;
    align-items: center;
    justify-content: center;
    background: rgba($color: #000, $alpha: 0.25);
    color: #fff;
    font-size: 3em;
    text-transform: uppercase;
  }

  @media screen and (max-width: 1026px) {
    &--title {
      font-size: 2em;
    }
  }
}
</style>
